<template>
  <div class="h-full">
    <Scrollbar>
    <div class="w-full">
    <div class="m-4" ref="title">
      <h1 class="school-title text-xl font-semibold text-blue-800">系统设置</h1>
    </div>
      <el-tabs class="m-4">
        <el-tab-pane label="学校设置">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm mr-10">
            <el-form-item label="学校名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="学校logo" prop="logo">
              <el-upload
                style="overflow: hidden"
                :limit="1"
                action="#"
                list-type="picture-card"
                :show-file-list="false"
                :http-request="onUploadPicture"
              >
                <img v-if="ruleForm.logo" :src="ruleForm.logo" class="avatar w-full" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="学校地址" prop="address">
              <el-input v-model="ruleForm.address"></el-input>
            </el-form-item>
            <el-form-item label="网站标题" prop="title">
              <el-input v-model="ruleForm.title"></el-input>
            </el-form-item>
            <el-form-item label="网站版权" prop="copyright">
              <el-input v-model="ruleForm.copyright"></el-input>
            </el-form-item>
            <el-form-item label="是否开启首页" prop="isHome">
              <el-radio-group v-model="ruleForm.isHome">
                  <el-radio :label="true">开启</el-radio>
                  <el-radio :label="false">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm','school')">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="学校领导">
          <el-form ref="principalForm" :model="principalForm" :rules="principalRules" label-width="100px">
            <el-form-item label="校长姓名" prop="value">
              <div class="flex">
                <el-input class="w-80 pr-1" readonly v-model="principalForm.value">
                  <template #prepend>
                    <el-button @click="checkedSign(principalForm.value)">查看</el-button>
                  </template>
                  <template #append >
                    <el-button @click="$refs['principal'].click()">上传</el-button>
                  </template>
                </el-input>
                <input v-show="false" ref="principal" type="file" @change="uploadSign($event,'principal')" >
                <el-button type="primary" @click="submitForm('principalForm','principal')">保存</el-button>
              </div>
            </el-form-item>
          </el-form>
          <el-form ref="teachingForm" :model="teachingForm" :rules="teachingRules" label-width="100px">
            <el-form-item label="教务主任" prop="value">
              <div class="flex">
                <el-input class="w-80 pr-1" readonly v-model="teachingForm.value">
                  <template #prepend>
                    <el-button @click="checkedSign(teachingForm.value)">查看</el-button>
                  </template>
                  <template #append>
                    <el-button  @click="$refs['teaching'].click()">上传</el-button>
                  </template>
                </el-input>
                <input v-show="false" ref="teaching" type="file" @change="uploadSign($event,'teaching')"  >
                <el-button type="primary" @click="submitForm('teachingForm','teaching')">保存</el-button>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="钉钉配置">
            <el-form ref="dingForm" :model="dingForm" :rules="dingRules" label-width="100px">
              <el-form-item label="钉钉地址" prop="baseUrl">
                <el-input v-model="dingForm.baseUrl"></el-input>
              </el-form-item>
              <el-form-item label="corpid" prop="corpid">
                <el-input v-model="dingForm.corpid"></el-input>
              </el-form-item>
              <el-form-item label="ssoSecret" prop="ssoSecret">
                <el-input v-model="dingForm.ssoSecret"></el-input>
              </el-form-item>
              <el-form-item label="AppKey" prop="AppKey">
                <el-input v-model="dingForm.AppKey"></el-input>
              </el-form-item>
              <el-form-item label="AppSecret" prop="AppSecret">
                <el-input v-model="dingForm.AppSecret"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('dingForm','ding')">保存</el-button>
              </el-form-item>
            </el-form>
        </el-tab-pane>
        <el-tab-pane label="评语设置">
          <el-form ref="commentForm" :model="commentForm" :rules="commontRules" label-width="120px" class="mr-10">
            <el-form-item label="评语截至时间" prop="time">
              <el-date-picker
                v-model="commentForm.time"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('commentForm','comment_enddate')">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="积分规则">
          <h1 class="border-l-8 border-blue-700 pl-2">打星计算</h1>
          <el-form ref="starForm" :model="starForm" :rules="starRules" label-width="80px" class="mr-10 mt-2">
            <el-form-item label="1星" prop="s1">
              <el-input-number :step="1" :min="1" v-model="starForm.s1"></el-input-number> 分
            </el-form-item>
            <el-form-item label="2星" prop="s2">
              <el-input-number :step="1" :min="1" v-model="starForm.s2"></el-input-number> 分
            </el-form-item>
            <el-form-item label="3星" prop="s3">
              <el-input-number :step="1" :min="1" v-model="starForm.s3"></el-input-number> 分
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('starForm','star_score')">保存</el-button>
            </el-form-item>
          </el-form>

          <h1 class="border-l-8 border-blue-700 pl-2">评级计算</h1>
          <el-form ref="levelForm" :model="levelForm" :rules="levelRules" label-width="80px" class="mr-10 mt-2">
            <el-form-item label="A" prop="A">
              <el-input-number :step="1" :min="1" v-model="levelForm.A"></el-input-number> 分
            </el-form-item>
            <el-form-item label="B" prop="B">
              <el-input-number :step="1" :min="1" v-model="levelForm.B"></el-input-number> 分
            </el-form-item>
            <el-form-item label="C" prop="C">
              <el-input-number :step="1" :min="1" v-model="levelForm.C"></el-input-number> 分
            </el-form-item>
            <el-form-item label="D" prop="D">
              <el-input-number :step="1" :min="1" v-model="levelForm.D"></el-input-number> 分
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('levelForm','level_score')">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    </Scrollbar>
    <el-image-viewer v-if="checkSign" :on-close="()=>checkSign = false" :url-list="[signURL]"></el-image-viewer>
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components:{
    ElImageViewer
  },
  data() {
    return {
      checkSign:false,
      signURL:'',
      
      ruleForm: {
        name: '',
        logo:'',
        address:'',
        title:'',
        copyright:'',
        isHome:'',
      },
      principalForm:{
        value:'',   // 校长姓名
      },
      teachingForm:{
        value:'',    // 教导主任
      },

      dingForm:{
        baseUrl:'',
        corpid:'',
        ssoSecret:'',
        AppKey:'',
        AppSecret:'',
      },
      starForm:{
        s1:0,
        s2:0,
        s3:0,
      },
      levelForm:{
        A:0,
        B:0,
        C:0,
        D:0,
      },

      dingRules:{
        baseUrl:[
          { required: true, message: '请输入钉钉地址', trigger: 'blur' },
        ],
        corpid:[
          { required: true, message: '请输入corpid', trigger: 'blur' },
        ],
        ssoSecret:[
          { required: true, message: '请输入ssoSecret', trigger: 'blur' },
        ],
        AppKey:[
          { required: true, message: '请输入AppKey', trigger: 'blur' },
        ],
        AppSecret:[
          { required: true, message: '请输入AppSecret', trigger: 'blur' },
        ]
      },
      rules: {
        name: [
          { required: true, message: '请输入学校名称', trigger: 'blur' },
        ],
        logo: [
          { required: true, message: '请上传学校logo', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入学校地址', trigger: 'blur' },
        ],
        title: [
          { required: true, message: '请输入网站标题', trigger: 'blur' },
        ],
        copyright:[
          { required: true, message: '请输入网站版权', trigger: 'blur' },
        ],
        isHome:[
          { required: true, message: '请选择是否开启首页', trigger:'blur' }
        ]
      },
      principalRules:{
        value:[
          { required: true, message: '请输入校长姓名', trigger: 'blur' },
        ],
      },
      teachingRules:{
        value:[
          { required: true, message: '请输入教导主任姓名', trigger: 'blur' },
        ]
      },

      starRules:{
        s1:[
          { required: true, message: '请输入打星积分', trigger: 'blur' },
        ],
        s2:[
          { required: true, message: '请输入打星积分', trigger: 'blur' },
        ],
        s3:[
          { required: true, message: '请输入打星积分', trigger: 'blur' },
        ]
      },
      levelRules:{
        A:[
          { required: true, message: '请输入A级积分', trigger: 'blur' },
        ],
        B:[
          { required: true, message: '请输入B级积分', trigger: 'blur' },
        ],
        C:[
          { required: true, message: '请输入C级积分', trigger: 'blur' },
        ],
        D:[
          { required: true, message: '请输入D级积分', trigger: 'blur' },
        ]
      },
      commentForm:{
        time:'',
      },
      commontRules:{
        time:[
          { required: true, message: '请选择评语截至时间', trigger: 'blur' },
        ]
      }
    };
  },
  mounted() {
    this.handleLoadSchool();  // 学校设置
    this.handleLoadPrincipal(); // 学校领导
    this.handleLoadTeaching(); // 教导主任
    this.handleLoadDing();  // 钉钉配置
    this.handleComment();   // 评语配置
    this.handleSetStar();   // 星积分配置
    this.handleSetLevel();   // 级积分配置
  },
  methods: {
    // 上传图片
    onUploadPicture(file) {
      let params = {
        file: file.file,
      };
      this.$cloud
        .upload("files/upload", params)
        .then((res) => {
          this.ruleForm.logo = res.url;
        })
    },
    
    // 上传签名
    uploadSign(e,type){
      console.log('e:',e,type)
      let file = e.target.files[0]
      this.$cloud
        .upload("files/upload", {
          file
        })
        .then((res) => {
          if(type === 'teaching'){
            this.teachingForm.value = res.url
          }else{
            this.principalForm.value = res.url
          }
        })
    },
    
    // 查看签名
    checkedSign(url){
      this.checkSign = true;
      this.signURL = url;
    },

    handleLoadSchool(){
      this.$cloud.get("setting/get",{key:"school"}).then(res=>{
        try{
          this.ruleForm = JSON.parse(res)
        } catch ( e ) {
          this.ruleForm = {
            name: '',
            logo:'',
            address:'',
          }
        }
      })
    },

    handleLoadPrincipal(){
      this.$cloud.get("setting/get",{key:"principal"}).then(res=>{
        try{
          this.principalForm.value = res
        } catch ( e ) {
          console.error('principal',e)
        }
      })
    },

    handleLoadTeaching(){
      this.$cloud.get("setting/get",{key:"teaching"}).then(res=>{
        try{
          this.teachingForm.value = res
        } catch ( e ) {
          console.error('teaching',e)
        }
      })
    },

    handleLoadDing(){
      this.$cloud.get("setting/get",{key:"ding"}).then(res=>{
        try{
          this.dingForm = JSON.parse(res)
        } catch ( e ) {
          this.dingForm = {
            baseUrl: '',
            corpid:'',
            ssoSecret:'',
            AppKey:'',
            AppSecret:'',
          }
        }
      })
    },

    handleComment(){
      this.$cloud.get("setting/get",{key:"comment_enddate"}).then(res=>{
        try{
          this.commentForm = JSON.parse(res)
        } catch ( e ) {
          this.commentForm = {
            time:'',
          }
        }
      })
    },

    handleSetStar() {
      this.$cloud.get('setting/get',{key:'star_score'}).then(res => {
        try{
          this.starForm = JSON.parse(res)
        } catch ( e ) {
          this.starForm = {
            s1:0,
            s2:0,
            s3:0
          }
        }
      })
    },
    handleSetLevel() {
      this.$cloud.get('setting/get',{key:'level_score'}).then(res => {
        try{
          this.levelForm = JSON.parse(res)
        } catch ( e ) {
          this.levelForm = {
            A:0,
            B:0,
            C:0,
            D:0
          }
        }
      })
    },

    submitForm(formName,key) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let value = ''
          if(formName === 'principalForm' || formName === 'teachingForm'){
            value = this[formName].value
          }else{
            value = JSON.stringify(this[formName])
          }
          this.$cloud
            .post(`setting/set`, {
              key,
              value: value
            })
            .then((res) => {
              this.$message.success('保存成功！');
            })
        }
      });
    },
  }
}
</script>
<style scoped>

</style>
